<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ page_name }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div v-if="isextend" class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="门店名称">
                                <el-input v-model="search.store_name"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                                   @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                                   @click="issearch = !issearch">显示搜索
                        </el-button>
                        <el-button icon="el-icon-plus" size="medium" type="primary" @click="to_store">去选门店</el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    size="medium"
                    border
                    v-loading="loading"
                    :data="tableData"
                    style="width: 100%">
                <el-table-column
                        prop="store_name"
                        label="门店名称">
                    <template slot-scope="scope">
                        <span>{{ scope.row.store_name }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="product_line_all"
                        label="消费卡可用产品线">
                    <template slot-scope="scope">
                        <div style="display:inline-block;margin-right: 10px"
                             v-for="(item,index) in scope.row.product_line_all" :key="index">
                            <el-tag size="mini" v-if="item===1">团课</el-tag>
                            <el-tag size="mini" v-if="item===2">训练营</el-tag>
                            <el-tag size="mini" v-if="item===3">私教</el-tag>
                            <el-tag size="mini" v-if="item===4">商品</el-tag>
                            <el-tag size="mini" v-if="item===5">活动</el-tag>
                            <el-tag size="mini" v-if="item===6">赛事</el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed="right"
                        label="操作"
                        width="200">
                    <template slot-scope="scope">
                        <el-button
                                v-if="is_auth('store.store.getinfo')"
                                @click="tosee(scope.row.store_uuid)"
                                size="mini">去设置
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination
                    @current-change="getlist"
                    :page-size="this.env.pageSize"
                    :pager-count="7"
                    background
                    layout="prev, pager, next, total"
                    :current-page.sync="page"
                    :total="count">
            </el-pagination>
        </div>
        <div v-else class="page-content-x">
            <div class="open-info">
                <div class="open-info-img">
                    <img src="https://img.gymmartsports.com/gymmartsports/materialimg/21b2d202109061840289545.png?imageslim"/>
                </div>
                <p>点击免费开通企业用户引流服务</p>
            </div>
            <div class="open-btn">
                <el-button type="primary" @click="()=>{this.isextend_agreement=true}">开通消费卡</el-button>
            </div>

            <!--消费卡开通协议-->
            <el-dialog
                    title="消费卡协议"
                    :visible.sync="isextend_agreement"
                    width="80%"
                    :close-on-press-escape="false"
                    :close-on-click-modal="false"
            >
                <section class="agree-view">
                    <div v-html="agreement.control"/>
                </section>
                <div class="agree-btn">
                    <el-button type="primary" @click="enable">同意并开通消费卡</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>

export default {
    components: {},
    data() {
        return {
            page_name: '消费卡门店',
            agreement: [],      // 开通协议
            isextend_agreement: false,
            isextend: true,

            loading: true,      // 加载状态
            issearch: true,     // 搜索是否展示
            search: {},         // 搜索内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [],      // 列表内容
        }
    },
    // 创建
    created() {
        this.get_extend()
        // this.init()

    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                time_type: 'start_time',
            }
            this.searchtime = null
            this.is_search();
        },
        // 获取扩展权限
        get_extend() {
            let postdata = {
                api_name: "consumercard.card.isextend",
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                console.log('json', json.agreement)
                // this.loading = false
                if (json.code === 0) {
                    this.isextend = true
                    this.init()
                } else {
                    this.isextend = false
                    this.agreement = json.agreement
                }
            })
        },
        // 消费卡扩展开通
        enable() {
            let postdata = {
                api_name: "consumercard.card.enable",
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            location.reload();
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "consumercard.card.getstorelist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 去设置
        tosee(store_uuid) {
            let toedit=1;
            this.$router.push({path: '/store/store/details', query: {store_uuid,toedit}})
        },
        // 去选门店
        to_store() {
            this.$router.push({path: '/store/store'})
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*表格不换行*/
.el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.el-table .cell {
    display: flex;
}

.form-tag_colour-div {
    min-height: 200px;
}

.open-info {
    width: 100%;
    text-align: center;

}

.open-info-img {
    width: 200px;
    margin: 50px auto;
}

.open-info-img img {
    width: 100%;
}

.open-info p {
    color: #999;
    margin-top: 60px;
    font-size: 14px;
}

.open-btn {
    margin-top: 20vh;
    text-align: center;
    margin-bottom: 10vh;
}

.agree-view {
    width: 100%;
    height: 60vh;
    overflow-y: scroll;
}

.agree-btn {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

</style>
